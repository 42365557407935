.aboutus-main-container{
    width: 100vw;
    height: auto;
}

.big-aboutus-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin-top: 25vh;

}

.big-aboutus-heading-group{
    position: relative;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items:center;
    font-family: 'Gowun Batang', serif;
    color: black;
    gap: 4vw;
   
}

.baboutusheader{
    font-size: 2.5rem;
    font-weight: bold;
}

.baboutusline1,
.baboutusline2,
.baboutusline3,
.baboutusline4{
    height: 3px;
    width: 35vw;
    background-color: black;
    border-radius: 20px;
}
.image-area1{
    position: relative;     
    gap: 4vh;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}

.imgg2,
.imgg1{
    height:50%;
    width: 80%;
    object-fit: cover;
}


.big-aboutus-footer-group{
    position: relative;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items:center;
    color: black;
    gap: 4vw;
    margin-bottom: 4rem;
}

.baboutusfooter img{
    width: 60px;
}

.baboutusfooter{
    width: 4rem;
}



@media only screen and (max-width: 768px){

    .aboutus-main-container{
        width: 100vw;
        height: 50%;
        background-image: none;
    }
    
    .big-aboutus-container{
        width: 100%;
        height: 65vh;
        display: flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        margin-top: 7rem;

    }
    
    .big-aboutus-heading-group{
        position: relative;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items:center;
        font-family: 'Gowun Batang', serif;
        color: black;
        gap: 4vw;
       
    }
    
    .baboutusheader{
        font-size: 1.5rem;
        font-weight: bold;
    }
    
    .baboutusline1,
    .baboutusline2,
    .baboutusline3,
    .baboutusline4{
        height: 3px;
        width: 30vw;
        background-color: black;
        border-radius: 20px;
    }
    .image-area1{
        position: relative;     
        gap: 4vh;
    }

    .imgg2,
    .imgg1{
        height:100%;
        width: 100%;
        object-fit: cover;
    }

    
    .big-aboutus-footer-group{
        position: relative;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items:center;
        color: black;
        gap: 4vw;
    }

    .baboutusfooter img{
        width: 40px;
    }
    
    .baboutusfooter{
        width: 4rem;
    }
    

}