.product-info-main-container{
    background-color: white;
    padding: 20px;
    margin-left: 0.7rem;
    display: flex;
    flex-direction: column;
}

.product-info-header{
    display: flex;
    justify-content: flex-start;
}

.product-info-header-text{
    font-size: 1.7rem;
    font-weight: bold;
    margin-left: 18px;
}

.product-info-content-area{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.admin-product-right-area,
.admin-product-left-area{
    padding: 20px;
    width: 50vw;
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: column;
}

@media only screen and (max-width: 768px){
    .product-info-content-area{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


