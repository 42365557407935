.recovery-main-container{
    height: 100vh;
    width: 100%;
    background-image: url('../../assets/bg/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position:relative;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-family: 'Gowun Batang', serif;
}

.recovery-header{
    font-size: 2.5rem;
    font-weight: 500;
}

.recovery-form{
    margin-top: 0.7vh;
    display:flex;
    flex-direction: column;
}

.recovery-email-input{
    width: 23vw;
    height: 5vh;
    border: none;
    
}

.recovery-email-input::placeholder{
    font-size: 16px; 
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    letter-spacing: 1px;
}

input[type="email"] {
    color: rgb(56,13,13,1);
    font-size: 16px; 
    border: none; 
    padding-left:10px;
    font-family: 'Gowun Batang', serif;

}

.recover-submit-button{
    width: calc(24vw-10px);
    height: 5vh;
    outline: none;
    border:none;
    background-color: rgb(16,15,15,0.58);
    color: white;
    font-family: 'Gowun Batang', serif;
    cursor: pointer;
    margin-bottom: 2vh;
    font-size: 20px;
    margin-top: 2vh;
}
.recover-instructions1{
    font-weight: bold;
}
.recovery-button{
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgb(104,14,14);
}
