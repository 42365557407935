.aboutUs-container {
    height: auto;
    width: 100%;
    margin-top: 60px;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    gap: 4vh;
}

.aboutUs-header{
    font-family: 'Gowun Batang', serif;
    font-size: 3.5vw;
    
}

.aboutUs-content{
    display: flex;
    align-items:center;
    justify-content: center;

}

.img1 img{
    border-radius: 20px 20px 0 0;
   
    margin-right: 1vw;

    height: 800px;
    width: 17.5vw;
}
.img2{
    /* height: 115vh; */

    background-size: cover;
    border-radius: 20px;
}
.img2 img{
    width: 835px;
    height: 800px;
    border-radius: 15px 15px 0 0;
    
}


.img3 img{
    height: 800px;
    width:17.5vw;
    border-radius: 20px 20px 0 0;
    
    margin-left: 1vw;
}


@media only screen and (max-width: 768px){

    .aboutUs-container {
        
        height: auto;
        width: 100%;
        margin-top: 60px;
        gap: 1.5vh;
    
    }
    .aboutUs-header{
        font-family: 'Gowun Batang', serif;
        font-size: 7.5vw;
        
    }
    .img1{
        display:none;

    }
    .img3{
       display:none;
    }
    .img2{
        height: 100%;
        width:100%;
        background-size: cover;
       
    }
    .img2 img{
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* Styles for landscape tablets */
}
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .aboutUs-container {
        height: auto;
        width: 100%;
        margin-top: 60px;
    
    }
    .img1{
        display:none;

    }
    .img3{
       display:none;
    }
    .img2{
        height: 100%;
        width:100%;
        background-size: cover;
    }
    .img2 img{
        width: 100%;
        height: 100%;
    }
  }

  @media only screen and (max-width: 870px) and (orientation: landscape){
    .img2{
        height: 100%;
        width:100%;
        background-size: cover;
    }
    .img2 img{
        width: 567px;
        height: 550px;
    }
    .img3 img, .img1 img{
        border-radius: 20px 20px 0 0;
       
        margin-right: 1vw;
    
        height: 550px;
        width: 16vw;
    }

  }
  