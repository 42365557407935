.shop-container {
    width: 100%;
    box-sizing: border-box;
    margin-top:6rem;
  }
  
  .category-header {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    
  }
  .category-headline{
    font-family: 'Gowun Batang', serif;
    font-size: 2.3rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .category-image{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .category-intro{
    font-family: "Dancing Script", cursive;
    font-size: 2.5rem !important;
    font-weight: bold;
    color: black !important;
  }
  
  .category-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
  }
  
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    
  }
  
  .product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    font-family: 'Gowun Batang', serif;
    width: 200px; 
    margin: 0 auto;
    cursor: pointer;
}

  
  .product-img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .product-details {
    margin-top: 10px;
  }
  
  .product-name {
    font-weight: bold;
  }
  
  .product-price {
    color: #e91e63;
    font-size: 1.1rem;
  }
  
  .product-disc {
    color: #555;
    font-size: 0.9rem;
  }
  /* Styles for mobile screens (max-width 600px) */

  @media (max-width: 768px) {

    .shop-container {
      width: 100%;
      box-sizing: border-box;
      margin-top:7rem;
    }
    .products-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px; 
    }

    .category-intro {
        font-size: 1.5rem !important;
    }

    .product-card {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      font-family: 'Gowun Batang', serif;
      width: 170px; 
      margin: 0 auto;
      cursor: pointer;
  }
}