.order-mgmt-main-container{
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: flex-start; */

}
.order-mgmt-main-header{
    font-size: 1.5rem;
    font-weight: bold;
}

.order-mgmt-table-container {
    margin-top: 2rem;
}


.order-mgmt-table-row{
    font-size: 0.9rem;
    text-align:center;
}