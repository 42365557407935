.main-container{
    display: flex;
    flex-direction: column;
    justify-contenct: center;
    width: 100%;
    height:100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.title-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4%;
    
}
.title1{
    font-size: 40px;
    text-shadow: 1px 1px 2px pink;
    font-family: 'Gowun Batang', serif;
    letter-spacing: 2px;
    color: black;
}

.line1{
    box-shadow: 2px 1.5px 1.5px grey;
   height: 2.5px;
   width: 200px;
   background-color: black;
   border-radius: 10px;
 
}
.line2{
    
    box-shadow: 2px 1.5px 1.5px grey;
    height: 2.5px;
    width: 200px;
    background-color: black;
    border-radius: 10px;
}

.categories{
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6vw;
    
}

.button-title-group{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    gap: 3vh;
    
}

.category-button{
    height: 115px;
    width: 115px;
    border-radius: 100%;
    border: 0px;
    background-color: transparent;
    box-shadow: 3px 2px 2px grey;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    justify-content:center;
    align-items:center;
}

img{
    width: 85%;
    height: 85%;
    object-fit: cover;
    overflow: hidden;

}


.category-name{
    font-family: 'Gowum Batang', serif;
    font-size: 20px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px grey;
}

.div1{
    display: flex;
    justify-content: center;
    gap: 6vw;

}
.div2{
    display: flex;
    justify-content: center;
    gap: 6vw;
    
}
.div3{
    display: flex;
    justify-content: center;
    gap: 6vw;
    
}

@media only screen and (max-width: 768px){

    .title-group{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2%;
        
    }
    .title1{
        font-size: 25px;
        text-shadow: 1px 1px 2px pink;
        font-family: 'Gowun Batang', serif;
        letter-spacing: 1px;
        color: black;
    }
    
    .line1{
    box-shadow: 2px 1.5px 1.5px grey;
       height: 2px;
       width: 16vw;
       background-color: black;
       border-radius: 10px;
     
    }
    .line2{
        
        box-shadow: 2px 1.5px 1.5px grey;
        height: 2px;
        width: 16vw;
        background-color: black;
        border-radius: 10px;
    }
    
    .categories{
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:5vh;

    }
    
    .button-title-group{
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        gap: 2vh;
        
    }
    
    .category-button{
        height: 100px;
        width: 100px;
        border-radius: 100%;
        border: 0px;
        background-color: transparent;
        box-shadow: 3px 2px 2px grey;
        overflow: hidden;
        transition: none;
        cursor: none;
        display: flex;
        justify-content: center;
        align-items:center;
     
    }
    
    img{
        width: 80%;
        height: 80%;
        object-fit: cover;
        overflow: hidden;
    
    }
    
    .category-name{
        font-family: 'Gowum Batang', serif;
        font-size: 17px;
        letter-spacing: 1px;
        text-shadow: 1px 1px 2px grey;
    }
    .div1{
        display: flex;
        justify-content: center;
        gap: 14vw;
    
    }
    .div2{
        display: flex;
        justify-content: center;
        gap: 14vw;
        
    }
    .div3{
        display: flex;
        justify-content: center;
        gap: 14vw;
        
    }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* Styles for landscape tablets */
  }
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .title-group{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 2%;
        
    }
    .title1{
        font-size: 25px;
        text-shadow: 1px 1px 2px pink;
        font-family: 'Gowun Batang', serif;
        letter-spacing: 1px;
        color: black;
    }
    
    .line1{
    box-shadow: 2px 1.5px 1.5px grey;
       height: 2px;
       width: 16vw;
       background-color: black;
       border-radius: 10px;
     
    }
    .line2{
        
        box-shadow: 2px 1.5px 1.5px grey;
        height: 2px;
        width: 16vw;
        background-color: black;
        border-radius: 10px;
    }
    
    .categories{
        margin-top: 4vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:4vh;

    }
    
    .button-title-group{
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        gap: 2vh;
        
    }
    
    .category-button{
        height: 100px;
        width: 100px;
        border-radius: 100%;
        border: 0px;
        background-color: transparent;
        box-shadow: 3px 2px 2px grey;
        overflow: hidden;
        cursor: pointer;
    }
    
    img{
        width: 90%;
        height: 90%;
        object-fit: cover;
        overflow: hidden;
    
    }
    
    .category-name{
        font-family: 'Gowum Batang', serif;
        font-size: 17px;
        letter-spacing: 1px;
        text-shadow: 1px 1px 2px grey;
    }

    .div1, .div2, .div3{
        display: flex;
        gap: 20vw;
    }
  }

  @media only screen and (max-width: 870px) and (orientation: landscape){
    .categories{
        margin-top: 5vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6vw;
        
    }
    
    .button-title-group{
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction: column;
        gap: 3vh;
        
    }
    
    .category-button{
        height: 85px;
        width: 85px;
        border-radius: 100%;
        border: 0px;
        background-color: transparent;
        box-shadow: 2px 1px 1px grey;
        overflow: hidden;
        
    }
    
    img{
        width: 90%;
        height: 90%;
        object-fit: cover;
        overflow: hidden;
    
    }
    
    
    .category-name{
        font-family: 'Gowum Batang', serif;
        font-size: 15px;
        letter-spacing: 1px;
        text-shadow: 1px 1px 2px grey;
    }
    
    .div1{
        display: flex;
        justify-content: center;
        gap: 6vw;
    
    }
    .div2{
        display: flex;
        justify-content: center;
        gap: 6vw;
        
    }
    .div3{
        display: flex;
        justify-content: center;
        gap: 6vw;
        
    }
  }
  

