.payment-success-container{
    text-align:center;
    padding: 5px;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    margin-top: 20vh;
    margin-left: 36vw;
    border:1px solid green;
    width: 30vw;

}

@media only screen and (max-width: 768px){
    .payment-success-container{
        text-align:center;
        display: flex;
        align-items:center;
        justify-content:center;
        flex-direction: column;
        border:1px solid green;
        margin-top: 20vh;
        margin-left: 5vw;
        width: 90vw;
    }
}