.shop {
    position: fixed;
    top: -100vh;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.8);
    transition: top 0.3s ease-in-out;
    z-index: 1000;
    font-family: 'Gowun Batang', serif;
   
}
  
  .shop.open {
    top: 0;
  }
  
  .shop-content {
    position: relative;
    margin: 20px;
    padding: 20px;
    background-color: transparent;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    color: white;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-button img {
    width: 20px;
    height: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin: 10px 0;
    font-size: 18px;
    cursor: pointer;
  }
  