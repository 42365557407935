.Reviews-Home-container {
    width: 100%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .heading{
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .reviews-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap: 7vh;
  }
  
  .rp-3,
  .rp-2,
  .rp-1{
    display: flex;
    justify-content: center;
    align-items:center;
    gap: 5vw;

  }


  .review-detail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 50%;
  }

  .person-name{
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
  }

  .review{
    font-size: 1rem;
    width: 80%;  
    font-family:'Gowun Batang', serif;      
    
  }

@media only screen and (max-width: 768px){
    .Reviews-Home-container {
        width: 100%;
        height: 400px;
        overflow: hidden;
        margin: 0 auto;
      }

      .heading{
        margin-top: 1vh;
        font-size: 6vw;
        margin-bottom: 2vh;
      }

      .reviews-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        gap: 4vh;
      }
      
      .rp-3,
      .rp-2,
      .rp-1{
        display: flex;
        justify-content: center;
        align-items:center;
        gap: 5vw;

      }


      .review-detail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        width: 50%;
      }

      .person-name{
        font-size: 4vw;
        font-weight: bold;
        width: 100%;
      }

      .review{
        font-size: 3.5vw;
        width: 80%;        
        
      }
      .star-rating{
        display: flex;
        align-items:center;
        justify-content:center;
      }

      
   

}

@media only screen and (max-width: 870px) and (orientation: landscape){
    .heading{
        font-size: 3vw;
        font-family: 'Gowun Batang', serif;
    }
    
    .reviews-space{
        padding: 4%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .reviews-container{
        height: 40vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(0,0,0,0.7);
        border-radius: 7px;
        padding: 2vw;
    }
    
    .product-details{
        margin-right: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;

    }
    
    .product-image{
        width: 9vw;
        height: 30vh;
    }
    
    .product-image img{
        height: 100%;
        width: 100%;
    }
    
    .review-detail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
    
    .person-name{
        position: relative;
        font-size: 2.4vw;
        transform: translateY(-100%);
        font-weight: bold;
    
    }
    
    .review{
        position: relative;
        transform: translateY(-10%);
        height: 15vh;
        width:  20vw;
        /* background-color: red; */
    
    }
    
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .reviews-container{
        height: 15vh;
    }

    .product-image{
        width: 13vw;
        height: 9vh;
    }

    .person-name{
        position: relative;
        font-size: 3.5vw;
        font-weight: bold;
        margin-top: 5vh;
    
    }
    
    .review{
        position: relative;
        transform: translateY(-10%);
        height: 15vh;
        width:  20vw;
        /* background-color: red; */
    
    }
    .review-detail{
        margin-left: 10px;
        
        /* font-weight: bold; */
    }
}