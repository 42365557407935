.login-main-container{
    height: 100vh;
    width: 100%;
    background-image: url('../../assets/bg/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position:relative;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-family: 'Gowun Batang', serif;
}

.login-header{
    font-size: 2.5rem;
    font-weight: 500;
}

.google-icon{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    background-color: white;
    border-radius: 50px;
    padding: 0.5rem;
    cursor: pointer;
}

.google-icon-button{
    background-color: transparent;
    border: none;
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;

}
.google-icon-button img{
    width: 2rem;
    margin-right: 10px;
}
.google-icon-button span{
    font-size: 1.5rem;
    font-family: 'Gowun Batang', serif;

}

.instructions{
    font-weight: bold;
}
.login-form{
    margin-top: 0.7vh;
    display:flex;
    flex-direction: column;
}

.login-email-input{
    width: 23vw;
    height: 5vh;
    border: none;
    
}

.pass-input{
    width: 23vw;
    height: 5vh;
    border: none;
    margin-top: 1.7vh;
    margin-bottom: 1.7vh;

}

.login-email-input::placeholder{
    font-size: 16px; 
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    letter-spacing: 1px;
}

.pass-input::placeholder{
    font-size: 16px; 
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    letter-spacing: 1px;
}


input[type="email"] {
    color: rgb(56,13,13,1);
    font-size: 16px; 
    border: none; 
    padding-left:10px;
    font-family: 'Gowun Batang', serif;

}


input[type="text"] {
    color: rgb(56,13,13,1);
    font-size: 16px; 
    border: none; 
    padding-left:10px;
    font-family: 'Gowun Batang', serif;

 
}
input[type="password"] {
    color: rgb(56,13,13,1);
    font-size: 16px; 
    border: none; 
    padding-left:10px;
    font-family: 'Gowun Batang', serif;

 
}

.login-form input:focus {
    outline: none; 
    
}

.login-submit-button{
    width: calc(24vw-10px);
    height: 5vh;
    outline: none;
    border:none;
    background-color: rgb(16,15,15,0.58);
    color: white;
    font-family: 'Gowun Batang', serif;
    cursor: pointer;
    margin-bottom: 2vh;
    font-size: 20px;
}

.recovery-instructions2{
    font-weight: bold;
}

.recovery-instructions1{
    font-weight: bold;
}

.recover-button{
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgb(104,14,14);
}

.create-button{
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    color: rgb(104,14,14);
}

@media only screen and (max-width: 768px){
    .login-email-input{
        width: 20rem;
        height: 2.7rem;
        border: none;
        
    }
    
    .pass-input{
        width: 20rem;
        height: 2.7rem;
        border: none;
        margin-top: 1.7vh;
        margin-bottom: 1.7vh;
    
    }

}