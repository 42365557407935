.main-admin-login-container {
    background-image: url(../../assets/Images/adminbg.jpg);
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7rem;
}

/* Desktop View */
.admin-login-box {
    /* position: absolute;
    transform: translate(+10rem, 10rem); */
    width: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 20rem;
    font-family: 'Gowun Batang', serif;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
}

.admin-logo {
    width: 10rem;
}

.admin-title {
    /* position: absolute;
    transform: translate(55rem, 10rem); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.admin-title-text {
    font-size: 3rem;
    font-family: 'Gowun Batang', serif;
    font-weight: bold;
    letter-spacing: 2px;
}

.admin-login-box-header {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
}

.admin-login-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
}

.admin-login-inputs Input {
    margin-bottom: 2rem;
    border: 1px solid black;
    border-radius: 10px;
    width: 25rem;
    height: 2.5rem;
    padding-left: 20px;
    color: black;
}

.admin-login-submit {
    border: 1px solid black;
    width: 6rem;
    height: 2rem;
    border-radius: 7px;
    background-color: white;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
}

/* Mobile View */
/* Mobile View */
@media only screen and (max-width: 768px) {
    .main-admin-login-container {
        flex-direction: column;
        padding: 20px;
    }

    .admin-title {
        position: relative;
        transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: auto;
        margin-top: 2rem; 
    }

    .admin-title-text {
        font-size: 2rem;
        letter-spacing: 1px;
    }

    .admin-logo {
        width: 5rem;
        margin-bottom: 0.5rem;
    }

    .admin-login-box {
        position: relative;
        transform: none;
        width: 100%;
        max-width: 20rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 20px;
        margin-bottom: 17rem;
    }

    .admin-login-inputs input {
        width: 100%;
        margin-bottom: 1rem; 
        color: black/* Reduced margin-bottom for tighter spacing */
    }
    .admin-login-inputs input::placeholder {
        width: 100%;
        margin-bottom: 1rem;
        color: rgb(0,0,0,0.5); /* Reduced margin-bottom for tighter spacing */
    }

    .admin-login-submit {
        width: 50%;
        height: 40px;

    }
}

