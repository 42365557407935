.div-area{
    margin-top: 60px;
    background-image: url('../../assets/Images/border.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 8px;
    background-size: cover;
    

}

