.WpLink-container{
    font-family: 'Gowun Batang', serif;
    width: 100vw;
}
.wplink-main-container{
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;

}

.wplink-intr3,
.wplink-intr2,
.wplink-intr1{
    margin-bottom: 2vh;
    font-size: 1.2rem;
    letter-spacing: 2px;
    font-weight: bold;
}

.whatsapp-button {
    margin-top: 4vh;
    background-color: #25D366; /* WhatsApp green color */
    color: white;
    border: none;
    padding: 10px 40px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Gowun Batang', serif;
    margin-bottom: 4vh;
  }

@media only screen and (max-width: 768px){
    .WpLink-container{
        font-family: 'Gowun Batang', serif;
        width: 100vw;
    }
    .wplink-main-container{
        margin-top: 6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;
    
    }

    .wplink-intr3,
    .wplink-intr2,
    .wplink-intr1{
        margin-bottom: 2vh;
        font-size: 1.2rem;
        letter-spacing: 2px;
        font-weight: bold;
    }

    .whatsapp-button {
        margin-top: 4vh;
        background-color: #25D366; /* WhatsApp green color */
        color: white;
        border: none;
        padding: 10px 40px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        font-family: 'Gowun Batang', serif;
        margin-bottom: 4vh;
      }

}