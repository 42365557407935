.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index:3;
}

.upperHalf1 {
  background-color: rgba(0, 0, 0, 0.5); 
  height: 70px;
  color: white; 
  padding: 10px;
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 2;
}

.logo-text-group1 {
  display: flex;
  flex-direction: row;
  align-items: center; 
}

.logo1 {
  width: 60px; 
  height: auto; 
  margin-right: 10px; 
}

.site-title1 {
  font-size: 50px;
  font-weight: bold;
  font-family: 'Gowun Batang', serif;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 768px) {
  .nav{
  position: relative;
  top: 0;
  width: 100%;
  margin-bottom: 30px;
  }
  .upperHalf1 {
    background-color: rgba(50, 0, 1, 0.9); 
    height: 60px;
  }

  .logo1 {
    width: 40px;
  }

  .site-title1 {
    font-size: 33px;
  }
}



@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .upperHalf1 {
    background-color: rgba(50, 0, 1, 0.9); 
  }
}



