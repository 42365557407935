.Profile-container{

}

.Profile-main-container{
    height: 850px;
    width: 100%;
    position:relative;
    display:flex;
    align-items:center;
    justify-content: center;
    font-family: 'Gowun Batang', serif;
    background-image: url('../../assets/bg/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
}

.white-blur-screen{
    width: 100%;
    height: 600px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-part{
    margin-top: 2vh;
    height: 80vh;
    width: 50%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
   

}

.profile-heading{
    /* background-color:red; */
    margin-left: 2vw;
    font-size: 2vw;
}

.purchased-orders{
    margin-top: 20vh;
    margin-left: 2vw;
    
}
.po-header{
    
    font-size: 2vw;
}

.heading-underline{
    width: 30vw;
    height: 0.2vh;
    background-color: black;
    margin-bottom: 2vh;
}

.right-part{
    height: 80vh;
    width: 50%;
    /* background-color: blue; */
    display: flex;
    
    flex-direction: column;
    text-align: right;
}

.button-area{
    margin-top: 2vh;
    margin-right: 2vw;
}

.profile-logout{
    height: 2.5rem;
    width: 7rem;
    background-color: transparent;
    elevation: 0; 
    outline: 0;
    border: 0;
    font-size: 24px;
    font-family: 'Gowun Batang', serif;
    cursor: pointer;
}

.Adress-area{
    margin-top: 20vh;
}

.add-header{
    font-size: 2vw;
}

.add-heading-underline{
    width: 30vw;
    height: 0.2vh;
    background-color: black;
    margin-bottom: 2vh;   
    margin-left: auto; 
   
}

.user-primary-address{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
}
.user-primary-address textarea{
    margin-left: auto; 
    margin-top: 0.5rem;
    width: 25rem;
}
.address-save-button{
    margin-left: auto;
    /* border: 1px solid black; */
    margin-top: 1rem;
    border-radius: 2px;
    width: 8rem;
    background-color: rgb(0,0,0,0.5);
    color: white;
}

@media only screen and (max-width: 768px){
    .Profile-main-container {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: 'Gowun Batang', serif;
        background-image: url('../../assets/bg/bg1.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    .white-blur-screen {
        width: 90%;
        margin-top: 8rem;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 20px; /* Added padding to white-blur area */
        position: relative; /* Make it relative to position the logout button */
    }

    .profile-heading {
        font-size: 1.5rem;
        margin-bottom: 1.5rem; /* Add margin for spacing */
        text-align: left;
    }

    .left-part {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .purchased-orders{
        margin-top: 5vh;
        
    }
    .po-header{
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
    }

    .heading-underline {
        width: 100%;
        height: 2px;
        background-color: black;
        /* margin: 1rem 0; */
    }


    .right-part {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }

    .button-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-logout {
        height: 2.5rem;
        width: 7rem;
        background-color: transparent;
        font-size: 18px;
        font-family: 'Gowun Batang', serif;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: right;
        padding-bottom: 1rem;
    }

    .Adress-area {
        width: 100%;
        margin-top: 2rem;
    }

    .add-header {
        font-size: 1.2rem;
        text-align: center;
        width: 100%;
    }

    .add-heading-underline {
        width: 100%;
        height: 2px;
        background-color: black;
    }

    .user-primary-address {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-primary-address textarea {
        width: 80%;
        margin-top: 0.5rem;
        padding: 10px;
        border-radius: 5px;
    }

    .address-save-button {
        margin-top: 1rem;
        border-radius: 5px;
        width: 7rem;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 10px;
        cursor: pointer;
        text-align: center;
    }
}





