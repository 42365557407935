.pd-container{
  width: 100vw;
  font-family: 'Gowun Batang', serif;
}

.pd-main-container{
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
}

.product-header{
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.pd-carousel-area {
  position: relative;
  overflow: hidden;
  margin-top: 2vh;
  display:flex;
  justify-content:center;
  align-items:center;
  height : 17rem;

  
}

.carousel-wrapper2 {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;

}

.carousel-slide2 {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item2 {
  min-width: 100%;

  display:flex;
  justify-content:center;
  align-items:center;
}

.product-photo2 {
  width: 20%;
  height: 65%;
  object-fit: contain;
  border-radius: 8px;
}

.carousel-button {
  background-color: transparent;
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
  z-index: 10;
}

.carousel-button.prev {
  left: 30%;
}

.carousel-button.next {
  right: 30%;
}

.product-name-cost{
  font-size: 1.5rem;
}
.instr-pd{
  font-size: 1rem;
}

.pd-Return-Policy,
.pd-care-info,
.pd-dimension,
.pd-description{
  text-align: left;
  margin-left: 4vw;
  margin-right: 4vw;
}
.pd-Return-Policy{
  margin-bottom:2rem;
}
.d-header{
  margin-top: 3vh;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items:center;

  
}
.rp-des{
  margin-bottom: 1rem;
}
.plus-icon {
  align-items:center;
  justify-content: center;
  display:flex;
  font-size: 1.1rem;
  margin-left: 25px;
  border: 1px solid black;
  height: 1.2rem;
  width: 1.2rem;
}
.link-rp {
  text-decoration: none;
  padding: 0.5rem;
  color: white;
  border-radius: 10px;
  background-color: rgba(50, 0, 1, 0.9);
  cursor: none;

}

.buy-now-button {
  padding: 0.4rem;
  color: white;
  border-radius: 10px;
  background-color: rgba(50, 0, 1, 0.9);
  cursor: pointer;
  elevation: none;
  border: none;
  font-family: 'Gowun Batang', serif;
  width: 10rem;
  height: 3rem;
  font-size: 1.2rem;
  margin-top: 2vh;
  

}

/* Overlay for the zoomed-in modal */
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

/* Modal content */
.modal-content {
position: relative;
background: white;
padding: 20px;
max-width: 70%;
max-height: 90%;
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;

}

/* Zoomed-in images */
.product-photo-modal {
width: 90%;
max-height: 90vh;
object-fit: contain;
transition: transform 0.3s ease-in-out;
/* cursor: zoom-in; */
/* background-color: red; */
}

/* On hover, image zooms slightly */
.product-photo-modal:hover {
transform: scale(2);
}

/* Close button */
.close-button {
position: absolute;
top: 10px;
right: 10px;
font-size: 30px;
background: none;
border: none;
color: white;
cursor: pointer;
width: 50px;
/* transform: translate(1rem); */
}

/* Carousel in Modal */
.carousel-wrapper-modal {
position: relative;
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
}

.carousel-slide-modal {
display: flex;
transition: transform 0.3s ease;
}

.carousel-item-modal {
min-width: 100%;
display:flex;
justify-content:center;
align-items:center;
}

/* Button Styles */
.carousel-button1 {
position: absolute;
/* top: 50%; */
transform: translateY(-70%);
/* background-color: rgba(0, 0, 0, 0.5); */
background-color: transparent;
color: black;
border: none;
/* padding: 10px; */
cursor: pointer;
z-index: 10;
font-size: 1.5rem;
}

.carousel-button1.prev {
left: 0;
}

.carousel-button1.next {
right: 0;
}

/* .carousel-button:hover {
background-color: rgba(0, 0, 0, 0.8);
} */

.zoom-controls {
position: absolute;
display: flex;
flex-direction: row;
justify-content: center;
bottom: 10rem;
align-items:center;
width: auto;
/* background-color:red; */
gap: 2rem
}

.zoom-button {
background-color: #ffffff;
border: 1px solid #ccc;
padding: 15px;
cursor: pointer;
margin-bottom: 10px;

}

.zoom-button:hover {
background-color: #f0f0f0;
}
.cut-price1{
  color: rgb(74,74,74,0.7); 
  text-decoration: line-through;
}
.diff-disc1{
  color: rgb(255,0,0,1); 
}



@media only screen and (max-width: 768px){
    .pd-container{
        width: 100vw;
        font-family: 'Gowun Batang', serif;
    }

    .pd-main-container{
        margin-top: 9rem;
        display: flex;
        flex-direction: column;
    }

    .product-header{
        font-size: 1.5rem;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .pd-carousel-area {
        position: relative;
        overflow: hidden;
        margin-top: 2vh;
 
        height : 17rem;
     
        
      }
      
      .carousel-wrapper2 {
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;

      }
      
      .carousel-slide2 {
        display: flex;
        transition: transform 0.5s ease-in-out;
      }
      
      .carousel-item2 {
        min-width: 100%;
        display:flex;
        justify-content:center;
        align-items:center;
      }
      
      .product-photo2 {
        width: 60%;
        height: 60%;
        object-fit: contain;
        border-radius: 8px;
      }
      
      .carousel-button {
        background-color: transparent;
        color: black;
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 1.5em;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-80%);
        z-index: 10;
      }
      
      .carousel-button.prev {
        left: 10px;
      }
      
      .carousel-button.next {
        right: 10px;
      }

      .product-name-cost{
        font-size: 1.5rem;
      }
      .instr-pd{
        font-size: 1rem;
      }

      .pd-Return-Policy,
      .pd-care-info,
      .pd-dimension,
      .pd-description{
        text-align: left;
        margin-left: 4vw;
        margin-right: 4vw;
      
      }
      .d-header{
        margin-top: 3vh;
        font-size: 1.2rem;
        font-weight: bold;
        display: flex;
        align-items:center;

        
      }
      .rp-des{
        margin-bottom: 2vh;
      }
      .plus-icon {
        align-items:center;
        justify-content: center;
        display:flex;
        font-size: 1.1rem;
        margin-left: 25px;
        border: 1px solid black;
        height: 1.2rem;
        width: 1.2rem;
      }
      .link-rp {
        text-decoration: none;
        padding: 0.5rem;
        color: white;
        border-radius: 10px;
        background-color: rgba(50, 0, 1, 0.9);
        cursor: none;
      }
      
      .buy-now-button {
        padding: 0.4rem;
        color: white;
        border-radius: 10px;
        background-color: rgba(50, 0, 1, 0.9);
        cursor: none;
        elevation: none;
        border: none;
        font-family: 'Gowun Batang', serif;
        width: 10rem;
        height: 3rem;
        font-size: 1.2rem;
        margin-top: 2vh;
        

      }

      /* Overlay for the zoomed-in modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  position: relative;
  background: white;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Zoomed-in images */
.product-photo-modal {
  width: 90%;
  max-height: 90vh;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  cursor: zoom-in;
  /* background-color: red; */
}

/* On hover, image zooms slightly */
.product-photo-modal:hover {
  transform: scale(2);
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  width: 50px;
  transform: translate(21rem);

  
}

/* Carousel in Modal */
.carousel-wrapper-modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-slide-modal {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel-item-modal {
  min-width: 100%;
  display:flex;
  justify-content:center;
  align-items:center;
}

/* Button Styles */
.carousel-button1 {
  position: absolute;
  /* top: 50%; */
  transform: translateY(-70%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: transparent;
  color: black;
  border: none;
  /* padding: 10px; */
  cursor: pointer;
  z-index: 10;
  font-size: 1.5rem;
}

.carousel-button1.prev {
  left: 0;
}

.carousel-button1.next {
  right: 0;
}

/* .carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */

.zoom-controls {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 10rem;
  align-items:center;
  width: auto;
  /* background-color:red; */
  gap: 2rem
}

.zoom-button {
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  
}

.zoom-button:hover {
  background-color: #f0f0f0;
}



   

      
      

}