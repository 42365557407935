.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3; /* Ensure navbar is above carousel */
}

  .upperHalf {
    background-color: rgba(0, 0, 0, 0.5); 
    height: 70px;
    color: white; 
    padding: 10px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    z-index: 2;

  }
  
  .logo-text-group {
    display: flex;
    flex-direction: row;
    align-items: center; 
    position: absolute; 
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    gap: 1vw; 
  }
  
  .logo {
    width: 60px; 
    height: auto; 
    margin-right: 10px; 
    align-items : center;
  }
  
  .site-title {
    font-size: 55px;
    font-weight: bold;
    font-family: 'Gowun Batang', serif;
    letter-spacing: 1.5px;
  }
  
  .lowerHalf {
    background-color: rgba(0, 0, 0, 0.5);
    color: white; 
    height: 40px;
    padding: 10px; 
    border-top: 2px solid #959595; 
    border-bottom: 2px solid #959595;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .icons-group {
    display: flex;
    align-items: center;
    position: absolute;
    right: 3%;
  }
  
  .icons-group button {
    background: none;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .icon {
    width: 25px; 
    height: auto;
    opacity: 0.7; 
    transition: opacity 0.3s ease; 
  }
  
  .icon:hover {
    opacity: 1; 
  }
  
  .navbar-button-group{
    display: flex;
    justify-content: center;
    background: transparent;
    gap: 40px;
    align-items: center;
  }
  
  .navbar-button {
    transition:  0.4s ease-in-out; 
    background: transparent;
    border: none;
    color: white;
    font-size: 17px;
    font-family: 'Gowun Batang', serif;
    letter-spacing: 1.5px;
    transform: translateY(-15%);
    cursor: pointer;
    text-shadow: 0px 0px 1px rgba(255, 255, 255, 0);
    text-decoration: 1px solid transparent;
  }
  
  .navbar-button:hover {
    text-decoration: underline solid currentColor; 

  }

  .mobile-menu-button{
    display:none;
  }

  @media only screen and (min-width: 769px) {
    .mobile-menu-button, .menu {
      display: none;
    }
  }

  @media only screen and (max-width: 768px){
    .nav{
      position: fixed;
      top: 0;
      width: 100%;

    }

    .cart-count{
      color: rgb(255,255,255,0.7);
      font-size: 1.2rem;
    }

    .upperHalf{
      height: 70px;
      /* align-items:center; */
      position: relative;
      background-color: rgba(50, 0, 1, 0.9); 
      display: flex;
      /* justify-content: center; */
      gap:0;
    }
    .mobile-lower-half{
      display: flex;
      justify-content: space-between;
      background-color: red;
      height: 50px;
      width: 100vw;
      background-color: rgba(50, 0, 1, 0.9);
      margin-top: 10px;
      align-items: center;
    }

    .logo-text-group{
      display:flex;
      align-items:center;
      flex-direction: row;
      justify-content: center;
      width: 100vw;
      /* margin-left: 2vh; */
      /* margin-right: 4vw; */
      /* background-color: yellow; */
       /* max-width: 500px;   */
    }
    .logo {
      width: 10vw;
    }
  
    .site-title {
      font-size: 2rem;
      /* letter-spacing: 0.3px; */
    }
    .icons-group {
      display: flex;
      align-items: center;
  
      /* right: 3%; */
    }
    
    .icons-group button {
      background: none;
      border: none;
      padding: 2px;
      cursor: pointer;
    }
    .lowerHalf{
      display: none;
    }

    .mobile-menu-button{
      display: flex;
      margin-left: 10px;
    }
    .menu-name{
      color: white;
      position: absolute;
      transform: translateX(55px);
      font-family: 'Gowun Batang', serif;
      font-size: 1.3rem;

      
    }

    .menu-button{
      background-color: transparent;
      width: 40px;
      border: none;
      /* margin-left: 1.5vw; */
    }

    .menu {
      position: fixed;
      top: 0;
      left: -60vw; /* Initially off-screen to the left */
      width: 60vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      transition: left 0.3s ease-in-out; /* Smooth animation for the left property */
      z-index: 1000;
      overflow-y: auto;
      font-family: 'Gowun Batang', serif;
    }
    
    .menu.open {
      left: 0; /* Slide in from the left */
    }

    .close-button {
      background: transparent;
      border: none;
      color: white;
      font-size: 24px;
      position: absolute;
      top: 1vh;
      left:5vw;
    }
  
    .close-button img {
      width: 20px; /* Adjust the size of the close icon */
    }
  
    .menu.open {
      left: 0;
    }
  
    .menu ul {
      text-align: left;
      list-style-type: none;
      padding: 0;
      margin-top: 40px;
    }
  
    .menu ul li {
      padding: 1rem 3rem;
    }
  
    .menu ul li a {
      color: white;
      text-decoration: none;
      font-size: 1.2rem;
    }
  
    .lowerHalf {
      display: none;
    }
    
  }

  @media only screen and (max-width: 870px) and (orientation: landscape){
    .nav {
      position: relative;
      top: 0;
      width: 100%;
      z-index: 3; /* Ensure navbar is above carousel */
    }

    .logo {
      width: 6vw; 
      height: auto; 
      margin-right: 10px; 
      align-items : center;
    }
    
    .site-title {
      font-size: 5vw;
      font-family: 'Gowun Batang', serif;
      letter-spacing: 1.5px;
    }

    .upperHalf{
      background-color: rgb(50, 0,1, 0.9);
    }

    .lowerHalf{
      background-color: rgb(50, 0,1, 0.9);
    }

  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .nav {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 3; /* Ensure navbar is above carousel */
    }
    .logo {
      width: 6vw; 
      height: auto; 
      margin-right: 10px; 
      align-items : center;
    }
    
    .site-title {
      font-size: 5vw;
      font-family: 'Gowun Batang', serif;
      letter-spacing: 1.5px;
    }

    .upperHalf{
      background-color: rgb(50, 0,1, 0.9);
    }

    .lowerHalf{
      background-color: rgb(50, 0,1, 0.9);
    }

  }

  




  