.main-area{
     display: flex;
     justify-content:center;
     flex-direction: column;
     margin-top: 60px;
     height: 100%;
     width: 100%;
     background-image: url('../../assets/Images/BestSellersBg.png');
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center ;
     border-radius: 20px;
}

.title-group{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4%;
}

.line11{
   height: 2.5px;
   width: 200px;
   background-color: white;
   border-radius: 10px;
}
.title{
    font-size: 40px;
    /* text-shadow: 1px 1px 2px pink; */
    font-family: 'Gowun Batang', serif;
    /* font-weight: bold; */
    letter-spacing: 2px;
    letter-spacing: 1px;
    color: white; 
}
.line22{
    /* box-shadow: 2px 1.5px 1.5px grey; */
    height: 2.5px;
    width: 200px;
    background-color: white;
    border-radius: 10px;
}

.blur-bg{
    background-color: rgba(108, 29, 29, 0.5);
    width: 80vw;
    height: 350px;
    margin-top: 30px;
    margin-bottom: 60px;
    transform: translateX(11.7%);
    border-radius: 15px;
    align-items:center;
    justify-content: center;
    display: flex;
}

.products{
    margin-left: 30px;
    margin-right: 30px;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    background-color: transparent;
}

.image-title-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 20%;
    height:105%;
    border: 0px;
    background-color: transparent;
    overflow: hidden;
    cursor: pointer;
    color: white;
}

.product-photo{
    width: 80%;
    height: 80%;

}
.product-name{
  font-size: 1.2rem;
  font-weight: bold;
}

.product-title1{
    font-family: 'Gowun Batang', serif;
    /* font-size: 1rem; */
    /* font-weight: bold; */
    color: white;
    margin-top: 0;
    letter-spacing: 1px;
}

.carousel-container-1 {
  position: relative;
  margin-top: 5%;
  width: 70%;
  height: 90%;
  overflow: hidden;
  /* background-color: black; */
}

.carousel-wrapper1 {
  display: flex;
  justify-content: center;
  align-items:center;

}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 5 / 3);
}

.carousel-item {
  min-width: 33.33%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
}

.product-photo {
  width: 60%;
  height: 90%;
  object-fit: contain;
}


.discount {
  position: absolute;
  top:1.5%;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: 'Gowun Batang', serif;
  font-size: 1.1rem;
}

.instr{
  position: absolute;
  top:6.5%;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: 'Gowun Batang', serif;
  font-size: 1.2rem;
}

.cut-price {
  text-decoration: line-through;
  color: rgb(215,215,215,1);
}

.carousel-button-prev,
.carousel-button-next {
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-25px);
}
.carousel-button-prev{
  left:5rem;
}
.carousel-button-next{
  right: 5rem;
}

.b-disc{
  font-size: 1.2rem;
  font-weight: bold;
}

.product-info-details{
  font-size: 0.9rem;
}
.diff-disc{
  color: rgb(0,255,34,1);


}

@media only screen and (max-width: 768px){

    .main-area{
        display: flex;
        justify-content:center;
        flex-direction: column;
        margin-top: 60px;
        height: 100%;
        width: 100%;
        background-image: url('../../assets/Images/BestSellersBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center ;
        border-radius: 20px;
   }
   
   .title-group{
       margin-top: 20px;
       display: flex;
       flex-direction: row;
       justify-content: center;
       gap: 4%;
   }
   
   .line11{
      /* box-shadow: 2px 1.5px 1.5px grey; */
      height: 2.5px;
      width: 18vw;
      background-color: white;
      border-radius: 10px;
   }
   .title{
       font-size: 7.5vw;
       /* text-shadow: 1px 1px 2px pink; */
       font-family: 'Gowun Batang', serif;
       /* font-weight: bold; */
       letter-spacing: 2px;
       letter-spacing: 1px;
       color: white; 
   }
   .line22{
       /* box-shadow: 2px 1.5px 1.5px grey; */
       height: 2.5px;
       width: 18vw;
       background-color: white;
       border-radius: 10px;
   }
   
   .blur-bg{
       background-color: rgba(108, 29, 29, 0.5);
       width: 80vw;
       height: 450px;
       margin-top: 30px;
       margin-bottom: 60px;
       transform: translateX(11.7%);
       border-radius: 15px;
       align-items:center; 
       justify-content: center;
       display: flex;
   }
   
   .carousel-container-1 {
    position: relative;
    margin-top: 20%;
    width: 80%;
    height: 90%;
    overflow: hidden;
    /* background-color: black; */
  }
  
  .carousel-wrapper1 {
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  .carousel-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    min-width: 100%;
    /* box-sizing: border-box; */
    padding: 10px;
    display: flex;
    align-items:center;
    justify-content:center;
  }
  
  .product-photo {
    width: 50%;
    height: 70%;
    object-fit: contain;
    margin-top: 5%;
  }

  
  .discount {
    position: absolute;
    top:1.5%;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Gowun Batang', serif;
    font-size: 1.1rem;
  }
  
  .instr{
    position: absolute;
    top:6.5%;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: 'Gowun Batang', serif;
  }
  
  .cut-price {
    text-decoration: line-through;
    color: rgb(215,215,215,1);
  }
  
  .carousel-button-prev,
  .carousel-button-next {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-35px);
  }
  .carousel-button-prev{
    left:5px;
  }
  .carousel-button-next{
    right: 5px;
  }

  .b-disc{
    font-size: 1.2rem;
    font-weight: bold;
  }
  
}

@media only screen and (max-width: 870px) and (orientation: landscape){
    .main-area{
        display: flex;
        justify-content:center;
        flex-direction: column;
        margin-top: 60px;
        height: 120vh;
        width: 100vw;
        background-image: url('../../assets/Images/BestSellersBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center ;
        border-radius: 20px;
   }
}

