

.cart-main-content{
    margin-top: 6rem;

}
.cart-header{
    font-family: 'Gowun Batang', serif !important;
    letter-spacing: 2px;
    font-size: 3rem;
}
.cart-item-font{
    font-family: 'Gowun Batang', serif !important;
    
}
.continue-shopping-option{
    font-family: 'Gowun Batang', serif !important;
}
.cart-product-data{
    font-family: 'Gowun Batang', serif !important;
    cursor: pointer;
}
.product-info-cart{
    display: flex;
    align-items:center;
    cursor: pointer;
}
.checkout-button{
    
}
@media only screen and (max-width: 768px){
    .cart-main-content{
        margin-top: 7vh;
    }
}