.admin-dashboard-main-container{
    background-color: white;
}

.card-area-admin{
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 2rem;
    gap: 5rem;
    color: white;
    font-size: 1.2rem;
}
.card-admin{
    width: 10rem;
}
.transaction-header{
    text-align: left;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: 'Robot', sans-serif;
}

.product-mgmt-main-container {
    background-color: white;
    padding: 20px;
}

.search-bar-container {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 2 rem; 
}
.product-search-bar{
    max-width: 25rem;
}

.product-table {
    /* margin: 0 auto;  */
    width: 100%;
    max-width: 80rem; 
    margin-top: 3rem;
}

.product-mgmt-header{
    font-size: 1.5rem;
    font-weight: bold;
}




@media only screen and (max-width: 768px){
    .card-area-admin{
        display: flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
        color: white;
        font-size: 1.2rem;
    }
    .card-admin{
        width: 15rem;
    }
}