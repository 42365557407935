
.contact-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 2rem;
    background-color: black;
    color: white;
    font-family: 'Gowun Batan', serif;
    position: relative;

  }
  
  .contact-header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    letter-spacing: 1px;
    margin-right:auto;
    margin-bottom: 10px;
    text-decoration: underline solid white;
    text-underline-offset: 4px;
    /* background-color: blue; */
  }

  .insta-icon{
    height: 5vh;
    width: auto;
    text-align:left;

  }
  
  .contact-info {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    gap:2.4vw;
    text-align:left;
   
  }
  
  .contact-details {
    line-height: 1;
    font-size: 1 vw;
    text-align: left;
  }

  .contact-details-content{
    margin-bottom: 9px;
  }
  .contact-links{
    flex-basis: 20%;
    display:flex;
    flex-direction: column; 
    text-align: center;
    /* background-color: blue; */
    text-align:left;
    text-decoration: none;
    color: white;
  }

  .contact-links a{
    text-decoration:none;
    color: white;
  }
  
  .newsletter-signup {
    /* flex-basis: 30%; */
    /* text-align: center; */
    /* background-color: green; */
    text-align:left;
    
  }
  
  .contact-links a {
    display: block;
    margin-bottom: 0.3rem;
  }

  .newsletter-signup h2 {
    margin-bottom: 1rem;
  }
  
  .newsletter-signup p {
    margin-bottom: 1rem;
  }

  .newsletter-form{
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2vh;

  }

  .email-input{
    height: 5vh;
    width: auto;
    font-family: 'Gowun Batang', serif;
    font-size: 1rem;
    border-radius: 0.5rem;
    font-weight: bold;
    color: black;
  }
  
  .email-input ::placeholder{
    margin-left: 0.3rem;
  }
  .email-submit{
    background-color: white;
    margin-left: 0.3rem;
    border-radius: 0.5rem;
    height: 5vh;
    width: 7vw;
    font-family: 'Gowun Batang', serif;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    outline:none;
    
  }

  
  
  .contact-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid #ddd;
  }

  .copyright{
    margin-right: 2rem;
  }
  
  .social-media a {
    margin-right: 1rem;
  } 
  

  .papers-heading{
    font-size: 1.5vw;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .email-input{
      height: 3.5vh;
    }
    .insta-icon{
      height: 5vw;
      width: auto;
      text-align:left;
  
    }


    .email-submit{
      height: 3.5vh;
      width: 20vw;
    }
  }
  @media only screen and (max-width: 768px){

    
    .contact-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 2rem;
      background-color: black;
      color: white;
      font-family: 'Gowun Batan', serif;
      position: relative;
      margin-top: 30px;
    }

    .contact-header {
      display:flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      letter-spacing: 1px;
      margin-right:auto;
      margin-bottom: 10px;
      text-decoration: underline solid white;
      text-underline-offset: 4px;
      /* background-color: blue; */
    }

    .insta-icon{
      height: 5vh;
      width: auto;
      text-align:left;

    }

    .contact-info {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      gap:5vw;
      text-align:left;

    
    }

    .contact-details {
      line-height: 1;
      font-size: 1 vw;
      text-align: left;
    }

    .contact-details-content{
      margin-bottom: 9px;
    }
    .contact-links{
      flex-basis: 20%;
      display:flex;
      flex-direction: column; 
      text-align: center;
      /* background-color: blue; */
      text-align:left;
      text-decoration: none;
      color: white;
    }

    .contact-links a{
      text-decoration:none;
      color: white;
    }

    .newsletter-signup {
      flex-basis: 30%;
      text-align: center;
      /* background-color: green; */
      text-align:left;
    }

    .contact-links a {
      display: block;
      margin-bottom: 0.3rem;
    }



    .newsletter-signup h2 {
      margin-bottom: 1rem;
    }

    .newsletter-signup p {
      margin-bottom: 1rem;
    }

    .newsletter-form{
      margin-bottom: 0.5rem;

    }

    .email-input{
      height: 5vh;
      width: auto;
      font-family: 'Gowun Batang', serif;
      font-size: 1rem;
      border-radius: 0.5rem;
      font-weight: bold;
      color: black;
    }

    .email-input ::placeholder{
      margin-left: 0.3rem;
    }
    .email-submit{
      background-color: white;
      margin-left: 0.3rem;
      border-radius: 0.5rem;
      height: 5vh;
      width: 27vw;
      font-family: 'Gowun Batang', serif;
      font-size: 1rem;
      font-weight: bold;
      border: none;
      outline:none;
      color: black;
    }



    .contact-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem 0;
      border-top: 1px solid #ddd;
    }

    .copyright{
      margin-right: 2rem;
    }

    .social-media a {
      margin-right: 1rem;
    } 


    .papers-heading{
      font-size: 7vw;
      margin-bottom: 10px;
    }


    
  }

  @media only screen and (max-width: 870px) and (orientation: landscape){
    .email-input{
      height: 7vh;
    }
    .insta-icon{
      height: 8vh;
      width: auto;
      text-align:left;

    }

    .email-input ::placeholder{
      margin-left: 0.3rem;
    }
    .email-submit{
      background-color: white;
      margin-left: 0.3rem;
      border-radius: 0.5rem;
      height: 7vh;
      width: 15vw;

    }
  }

