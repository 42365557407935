.main-ordersummary-page-container{
    width: 100%;
    font-family: 'Gowun Batang', serif;
    
}
.ordersummary-page-area{
    height: 100vh;
    align-items: center;
    justify-content:center;
    display: flex;
}

.ordersummary-area{
    height:80%;
    padding:30px;
    width:80% ;
    margin-top:7rem;
    border-radius: 20px;
    box-shadow: 10px 10px 25px 7px grey;
}

.ordersummary-area-header{
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 1px;
    
}

.ordersummary-area-body {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items at the top */
    height: 100%; 
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    gap: 35px;
  }

.ordersummary-area-body-left{
    width: 50%;
    /* border: 1px solid black; */
    height: 90%;

}
.ordersummary-area-body-right {
    width: 50%;
    max-height: 26rem; /* Or any other value that suits your design */
    overflow-y: auto; /* This will enable scrolling when the content exceeds the max-height */
    padding-right: 10px; /* Optional: prevents scrollbar from being too close to content */
}
  

.os-area-body-header{
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 1.2rem;
}

.os-area-body{
    margin-top: 1rem;
    justify-content: flex-start;
    display: flex;
}

.os-area-form{
    display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction: column;
}

.os-area-form-field {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    width: 100%; 
    padding: 0.5rem; 
}

.os-area-form-field span {
    margin-right: 1rem; 
    flex-shrink: 0; 
}

.os-area-form-field input {
    flex: 1; 
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
    padding-left: 10px;
    
}

.error-message{
    width: 100%; 
    text-align: left;
    color: red;
    font-size: 0.875rem;
    margin-top: 0.25rem;
    
}

.os-area-form-deliverto{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color:red;
    
}

.proceed-to-payment-button{
    border-radius: 5px;
    width:20rem;
    background-color: rgb(0,0,0,0.7);
    color: white;
    height: 2rem;
}

  
  
  .products-container {
    display: flex;
    justify-content:flex-start;
    text-align: left;
    flex-direction: column;
    gap: 10px;
  }
  
  .product-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .product-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .product-quantity input {
    width: 50px;
    margin-left: 10px;
  }
  
  .product-prices span {
    display: block;
  }
  
  .summary-section {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    font-weight: bold;
    text-align: right;
  }

  .product-quantity {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 10px;
}

.quantity-controls {
    display: flex;
    align-items: center;
    /* gap: 5px; */
}
.quantity-btn1{
    margin-left: 10px;
}

.quantity-btn1,
.quantity-btn {
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

.quantity-btn:hover {
    background-color: #ddd;
}

input[type="number"] {
    width: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
}

.stock-warning {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}

.os-back-button{
    position: absolute;
    transform: translateX(-36vw);
    top: 24vh;
    color: black;

}

.os-note{
    position: absolute;
    top: 20vh;
    right: 12vw;
}
.form-navgav{
    display: flex;
    align-items:center;
}
.deliver-to-text{
    text-align: left;
}


@media only screen and (max-width:768px){
    .deliver-to-text{
        display: none;
    }
    .form-navgav{
        flex-direction: column;
        justify-content: flex-start;
        gap:5px;
    
    }
    .os-area-body{
      margin-left:0;  
    }
    .os-area-form{
        width: 100%;
    }
    .ordersummary-page-area{
        margin-top: 8vh;
    }
    /* .ordersummary-area{
        margin-top: 10rem;
    } */
    .ordersummary-area-body {
        flex-direction: column;
        gap: 20px;
    }

    .ordersummary-area-body-left,
    .ordersummary-area-body-right {
        width: 100%;
    }

    .ordersummary-area {
        width: 90%;
        height: auto;
        padding: 20px;
        margin-top: 5rem;
    }

    .proceed-to-payment-button {
        width: 100%;
        height: 2.5rem;
    }

    .os-area-form-field input {
        padding-left: 5px;
    }

    .products-container {
        gap: 15px;
    }

    .os-back-button{
        transform: translateX(-36vw);
        position: absolute;
        top: 18vh;
    }
    .os-note{
        top:15vh;
    }

}




@media only screen and (max-height: 700px) and (max-width: 600px) {
    .os-back-button{
        transform: translateX(-36vw);
        position: absolute;
        top: 27vh;
    }
    .os-note{
        top:20vh;
    }
    .ordersummary-page-area{
        margin-top: 27vh;
        margin-bottom: 20vh;
    }
   
}