.main-area1{
    display: flex;
    justify-content:center;
    flex-direction: column;
    margin-top: 60px;
    height: 100%;
    width: 100%;
    background-image: url('../../assets/Images/NewArrivalsBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
}

.title-group{
   margin-top: 20px;;
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 4%;
}

.line11{
  /* box-shadow: 2px 1.5px 1.5px grey; */
  height: 2.5px;
  width: 200px;
  background-color: white;
  border-radius: 10px;
}
.title{
   font-size: 40px;
   /* text-shadow: 1px 1px 2px pink; */
   font-family: 'Gowun Batang', serif;
   /* font-weight: bold; */
   letter-spacing: 2px;
   letter-spacing: 1px;
   color: white; 
}
.line22{
   /* box-shadow: 2px 1.5px 1.5px grey; */
   height: 2.5px;
   width: 200px;
   background-color: white;
   border-radius: 10px;
}

.blur-bg{
   background-color: rgba(108, 29, 29, 0.5);
   width: 80vw;
   height: 450px;
   margin-top: 30px;
   margin-bottom: 60px;
   transform: translateX(11.7%);
   border-radius: 15px;
   align-items:center; 
   justify-content: center;
   display: flex;
}

/* .carousel-container-2 {
position: relative;
margin-top: 20%;
width: 90%;
height: 90%;
overflow: hidden;



}

.carousel-wrapper2 {
display: flex;
justify-content: center;
align-items:center;


}

.carousel-slide1{
display: flex;
transition: transform 0.5s ease-in-out;
margin-top: 10%;
}

.carousel-item {
min-width: 100%;
box-sizing: border-box;
padding: 10px;
}

.product-photo1 {
width: 70%;
height: 70%;
object-fit: contain;

} */


.discount {
position: absolute;
top:1.5%;
color: white;
padding: 5px;
border-radius: 5px;
font-family: 'Gowun Batang', serif;
font-size: 1.2rem;
}

.instr{
position: absolute;
top:6.5%;
color: white;
padding: 5px;
border-radius: 5px;
font-family: 'Gowun Batang', serif;
}

.cut-price {
text-decoration: line-through;
}

.carousel-container-2 {
   position: relative;
   margin-top: 5%;
   width: 70%;
   height: 90%;
   overflow: hidden;
   /* background-color: black; */
 }
 
 .carousel-wrapper2 {
   display: flex;
   justify-content: center;
   align-items:center;
 }
 
 .carousel-slide1 {
   display: flex;
   transition: transform 0.5s ease-in-out;
   width: calc(100% * 5 / 3);
 }
 .product-photo1 {
   width: 60%;
   height: 75%;
   object-fit: contain;
 }

 @media only screen and (max-width: 768px){
   .carousel-container-2 {
      position: relative;
      margin-top: 20%;
      width: 100%;
      height: 100%;
      overflow: hidden;
      /* background-color: black; */
    }
    .product-photo1 {
      margin-top: 12%;
      width: 60%;
      height: 75%;
      object-fit: contain;
      /* border-radius: 20px; */
    }
 }

